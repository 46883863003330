import React from 'react';
import dayjs from 'dayjs';

import { getListingAddress } from 'utils/listing';
import { formatCurrency } from 'utils/rifmFormatters';
import { getDateDifference } from 'utils/date';
import { Icon } from 'components';
import AccordionTableItem from 'components/AccordionTableItem';
import ListingPhotoAndAddress from './ListingPhotoAndAddress';

interface ListingItemPurchaseProps {
  data: Listing;
  onClickMenu?: (value: ListingPopupData) => void;
}

const ListingItemPurchase: React.FC<ListingItemPurchaseProps> = ({ data }) => {
  return (
    <AccordionTableItem
      fields={[
        {
          key: 'address',
          label: 'Address',
          value: (
            <ListingPhotoAndAddress
              src={data.images?.[0]}
              address={getListingAddress(data as any)}
            />
          )
        },
        {
          key: 'type',
          label: 'Type',
          value: data.propertyType || '-'
        },
        {
          key: 'date',
          label: 'Listed',
          value: getDateDifference(data.publishDate)?.text || '-'
        },
        {
          key: 'offer_price',
          label: 'Offer Price',
          value: data.lastOfferAmount ? formatCurrency(data.lastOfferAmount) : '-'
        },
        {
          key: 'offer_date',
          label: 'Offer Date',
          value: data.lastOfferDate ? dayjs.unix(data.lastOfferDate).format('MM/DD/YYYY') : '-'
        },
        {
          key: 'earnest_money',
          label: 'Earnest Money',
          value: data.earnestMoney ? formatCurrency(data.earnestMoney) : '-'
        },
        {
          key: 'financials',
          label: 'Financials',
          value: data.financials || '-'
        },
        {
          key: 'closing_date',
          label: 'Closing Date',
          value: data.closingDate ? dayjs.unix(data.closingDate).format('MM/DD/YYYY') : '-'
        },
        {
          key: 'actions',
          label: '',
          value: data.url && (
            <a className="table-header-link" href={data.url} target="_blank" rel="noreferrer">
              <span>View page</span>&nbsp;
              <Icon name="arrow" size={12} />
            </a>
          )
        }
      ]}
    />
  );
};

export default ListingItemPurchase;
