import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { SortDashboardKeys } from 'enums';
import notify from 'notify';
import { QueryParamConfig, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { tryGetFirstError } from 'utils/requests';
import usePersistedState from 'hooks/usePersistedState';
import { getDashboardData } from './api';

type QueryFilters<T> = {
  [K in keyof T]: T[K];
};
interface DashboardContextValues {
  listings?: Listing[];
  getListings: (filters: any) => Promise<void>;
  setQueryFilters: (value: QueryFilters<DashboardQuery>) => void;
  query: DashboardQuery;
  handleSort: (value?: DashboardTableSortKeys) => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  listingsTotal: Record<DashboardTabKey, number> | null;
}

const DashboardContext = React.createContext({} as DashboardContextValues);

const defaultView: DashboardViewKey = 'list';
const defaultTab: DashboardTabKey = 'sales';
const defaultSort: DashboardTableSortKeys = SortDashboardKeys.PublishDate;
const defaultOrder: SortDirection = 'desc';

export const DashboardContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [listings, setListings] = usePersistedState<Listing[]>('listings', []);
  const [listingsTotal, setListingsTotal] = useState<Record<DashboardTabKey, number> | null>(null);
  const abortController = useRef<AbortController | null>(null);
  const [query, setQuery] = useQueryParams({
    view: withDefault(StringParam, defaultView) as QueryParamConfig<DashboardViewKey>,
    tab: withDefault(StringParam, defaultTab) as QueryParamConfig<DashboardTabKey>,
    sort: withDefault(StringParam, defaultSort),
    order: withDefault(StringParam, defaultOrder) as QueryParamConfig<SortDirection>,
    search: withDefault(StringParam, '')
  });
  const { search, sort, order, tab } = query;

  const setQueryFilters = (value: DashboardQuery) => {
    setQuery(value, 'replaceIn');
  };

  const [debouncedFetchListings] = useDebouncedCallback(async query => {
    await getListings(query);
  }, 300);

  const handleSort = (key?: DashboardTableSortKeys) => {
    const { sort, order } = query;
    let sortValues: { sort?: DashboardTableSortKeys; order?: SortDirection } = {
      sort: undefined,
      order: undefined
    };

    if (sort !== key) {
      sortValues = { sort: key, order: 'desc' };
    } else if (order === 'desc') {
      sortValues = { sort: key, order: 'asc' };
    }

    setQuery({ ...sortValues }, 'replaceIn');
  };

  const getListings = useCallback(
    async filters => {
      abortController.current?.abort();
      abortController.current = new AbortController();
      setLoading(true);
      try {
        const { listingsTotal, listings } = await getDashboardData(
          filters,
          abortController.current?.signal
        );
        setListings(listings);
        setListingsTotal(listingsTotal);
      } catch (err) {
        if (err.message !== 'The user aborted a request.') notify(tryGetFirstError(err));
      }
      setLoading(false);
    },
    [setListings]
  );

  useEffect(() => {
    debouncedFetchListings({ search, sort, order, tab });
  }, [getListings, debouncedFetchListings, search, sort, order, tab]);

  const values: DashboardContextValues = {
    listingsTotal,
    listings,
    getListings,
    setQueryFilters,
    query,
    handleSort,
    loading,
    setLoading
  };
  return <DashboardContext.Provider value={values}>{children}</DashboardContext.Provider>;
};

export default DashboardContext;
