/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import Icon from 'components/Icon';

interface ContactInfoProps {
  className?: string;
  contactTitle: string;
  contactPerson: any;
}

const ContactInfo: React.FC<ContactInfoProps> = ({ className, contactPerson, contactTitle }) => {
  const contactName = contactPerson?.name;
  const contactEmail = contactPerson?.email;
  const contactPhone = contactPerson?.phone || contactPerson?.mobilePhone;
  const contactBusinessPhone = contactPerson?.businessPhone || contactPerson?.officePhone;
  const allContacts = [contactName, contactEmail, contactPhone, contactBusinessPhone];
  const isNoContactData = contactPerson && allContacts.every(value => !value);

  const formattedEmail = Array.isArray(contactEmail) ? (
    contactEmail.map(email => <span key={email}>{email}</span>)
  ) : (
    <>{contactEmail && contactEmail.split(';').map(email => <span key={email}>{email}</span>)}</>
  );

  if (!contactPerson || isNoContactData) return null;

  return (
    <StyledContactInfo className={clsx('contact-info', className)}>
      <div>
        <div className="contact-info__info-header">{contactTitle}</div>
        {contactName && <div className="contact-info__item name">{contactName}</div>}
        {contactEmail && (
          <div className="contact-info__item email">
            <Icon name="email" />
            <a href={`mailto:${contactEmail}`} className="link">
              {formattedEmail}
            </a>
          </div>
        )}
        {contactBusinessPhone && (
          <div className="contact-info__item">
            <Icon name="phone" />
            <a href={`tel:${contactBusinessPhone}`} className="link">
              {contactBusinessPhone}
            </a>
          </div>
        )}
        {contactPhone && (
          <div className="contact-info__item">
            <Icon name="smartphone" />
            <a href={`tel:${contactPhone}`} className="link">
              {contactPhone}
            </a>
          </div>
        )}
      </div>
    </StyledContactInfo>
  );
};

export default ContactInfo;

const StyledContactInfo = styled.div`
  display: flex;
  border: 1px solid #cdcdcd;
  border-radius: 6px;
  padding: 16px;
  margin: 0 0 16px;

  .contact-info {
    &__container {
      margin: 0 0 0 50px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    &__cols {
      display: flex;
      & > div {
        flex: 1;
        min-width: 120px;
        &.email {
          flex: 2;
        }
      }
    }
    &__info-block,
    &__item {
      margin: 0 12px 0 0;
    }
    &__info-header {
      font-size: 12px;
      line-height: 16px;
      color: ${props => props.theme.colors.grayDark};
      margin: 0 12px 12px 0;
      text-align: left;
    }
    &__item {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      color: ${props => props.theme.colors.mineShaft};
      .icon {
        margin: 0 5px 0 0;
        flex-shrink: 0;
      }
      &.name {
        font-weight: 600;
      }
      &.price,
      &.data {
        color: black;
      }
      &.email {
        span {
          display: block;
          margin-bottom: 3px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          &:last-of-type {
            margin: 0;
          }
        }
      }
      .link {
        text-decoration: none;
        color: unset;
        flex-shrink: 0;
        white-space: nowrap;
        max-width: 100%;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    margin: 0;
    height: 100%;
    .contact-info {
      height: 100%;
      &__container {
        padding: 12px 0;
      }
      &__info-item {
        margin: 0 32px 0 0;
      }
    }
  }
`;
