import { useEffect, useState } from 'react';

const getLocalStorageInitialState = (key: string) => {
  const item = localStorage.getItem(key);
  if (!item) return;
  return JSON.parse(item);
};

export default function usePersistedState<S>(
  key: string,
  initialState: S | (() => S)
): [S, React.Dispatch<React.SetStateAction<S>>] {
  const [state, setState] = useState(() => getLocalStorageInitialState(key) || initialState);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}
