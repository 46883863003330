import React from 'react';
import { Router } from '@reach/router';
import DashboardPage from 'views/DashboardPage';
import PrivateRoute from 'components/PrivateRoute';
import Workspace from 'workspace';

const Index = () => {
  return (
    <Workspace>
      <Router>
        <PrivateRoute path="/" component={DashboardPage} />
      </Router>
    </Workspace>
  );
};

export default Index;
