import React from 'react';
import useLayout from 'hooks/useLayout';
import ContactInfo from './ContactInfo';
import ListingContactsCarousel from './ListingContactsCarousel';

interface ListingContactsProps {
  className?: string;
  data: Listing;
}

const ListingContacts: React.FC<ListingContactsProps> = ({ data }) => {
  const isMobile = useLayout() === 'mobile';

  const sellType = data.type === 'Sell';
  const buyType = data.type === 'Buy';

  const seller = sellType && data.seller && (
    <ContactInfo key="Seller" contactTitle="Seller" contactPerson={data.seller} />
  );

  const buyerBroker = sellType && data.buyerbroker && (
    <ContactInfo key="Buyer Broker" contactTitle="Buyer Broker" contactPerson={data.buyerbroker} />
  );

  const sellerAttorney = sellType && data.sellerattorney && (
    <ContactInfo
      key="Seller Attorney"
      contactTitle="Seller Attorney"
      contactPerson={data.sellerattorney}
    />
  );

  const buyer = buyType && data.buyer && (
    <ContactInfo key="Buyer" contactTitle="Buyer" contactPerson={data.buyer} />
  );

  const sellerBroker = buyType && data.sellerbroker && (
    <ContactInfo
      key="Seller Broker"
      contactTitle="Seller Broker"
      contactPerson={data.sellerbroker}
    />
  );

  const buyerAttorney = buyType && data.buyerattorney && (
    <ContactInfo
      key="Buyer Attorney"
      contactTitle="Buyer Attorney"
      contactPerson={data.buyerattorney}
    />
  );

  const existingRoles = () => {
    const allRoles = [seller, buyerBroker, sellerAttorney, buyer, sellerBroker, buyerAttorney];
    return allRoles.filter(role => role);
  };

  return (
    <>
      {!isMobile && <>{existingRoles()}</>}
      {isMobile && (
        <ListingContactsCarousel amountCards={existingRoles()}>
          {existingRoles()}
        </ListingContactsCarousel>
      )}
    </>
  );
};

export default ListingContacts;
