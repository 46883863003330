import React from 'react';

import styled from '@emotion/styled';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Input, { InputProps } from 'components/Input';

const Search: React.FC<InputProps> = ({ placeholder, clear, ...props }) => (
  <StyledSearch className="search">
    <Input
      icon="search"
      iconPosition="left"
      placeholder={placeholder ?? `Search`}
      className="search-input"
      {...props}
    />
    {clear && props.value && (
      <Button simple onClick={clear} className="cancel-search" data-cy="button_cancel_search">
        <Icon name="mini-x" size={8} />
      </Button>
    )}
  </StyledSearch>
);

export default React.memo(Search);

const StyledSearch = styled.div`
  position: relative;

  .cancel-search {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    fill: ${props => props.theme.colors.red};
  }

  .search-input {
    width: 300px;
    margin: 0 32px 0 0;
    .input-wrapper .input-component {
      height: 32px;
      padding-right: 26px;
    }
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      width: 100%;
      margin: 0;
    }
  }
`;
