import { fetch, fetchWithToken } from 'utils/fetch';
import keysToCamel from 'utils/keysToCamel';
import { mapListings } from 'utils/mapping';
import { urlEncodeBody } from 'utils/requests';
import { info } from 'sitedata';

const { commonApiUrl, deploymentUrl } = info;

export async function getDashboardData(
  filters: { search?: string; sort?: string; order: 'desc' | 'asc'; tab: DashboardTabKey },
  signal?: AbortController['signal']
): Promise<{ listings: Listing[]; filters: any; listingsTotal: Record<DashboardTabKey, number> }> {
  const response = await fetchWithToken('/dashboard/v2', {
    query: urlEncodeBody(filters).toString(),
    signal
  });

  const data = await response.json();

  return {
    listingsTotal: data.total,
    listings: mapListings(data.listings),
    filters: keysToCamel(data.filters)
  };
}

export async function getListingOrders(): Promise<ListingOrder[]> {
  const strippedDeploymentUrl = deploymentUrl.replace(/^.*:\/\//i, '');
  const response = await fetch(`${commonApiUrl}/service/content/${strippedDeploymentUrl}`);
  const data = await response.json();
  const uniqueItems: string[] = [];
  const result: ListingOrder[] = [];

  data.options
    .filter(item => item.active)
    .forEach(item => {
      if (uniqueItems.includes(item.header)) return;
      uniqueItems.push(item.header);
      result.push({
        id: item.hash,
        title: item.header,
        description: item.description,
        price: item.price
      });
    });

  return result;
}

export async function createListing(data: CreateListingFormValues): Promise<void> {
  const response = await fetchWithToken('/listings', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });

  await response.json();
}
