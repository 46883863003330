import React from 'react';
import clsx from 'clsx';
import { useMediaPredicate } from 'react-media-hook';
import { Carousel } from 'react-responsive-carousel';
import { Props as CarouselProps } from 'react-responsive-carousel/lib/ts/components/Carousel';
import styled from '@emotion/styled';

interface ListingContactsCarouselProps extends Partial<CarouselProps> {
  className?: string;
  amountCards: Array<{ [key: string]: any }>;
}

const ListingContactsCarousel: React.FC<ListingContactsCarouselProps> = ({
  children,
  amountCards,
  className,
  ...props
}) => {
  const isCenterMode = useMediaPredicate(`(min-width: 412px)`);

  return (
    <StyledCarousel
      className={clsx('listing-carousel', className)}
      showStatus={false}
      showArrows={false}
      centerMode={isCenterMode}
      showThumbs={false}
      showIndicators={amountCards.length > 1}
      {...props}>
      {children}
    </StyledCarousel>
  );
};

export default React.memo(ListingContactsCarousel);

const StyledCarousel = styled(Carousel)`
  outline: none;
  background: #fff;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    transition: all 0.25s ease-in;
    position: absolute;
    z-index: 2;
    top: 20px;
    background: none;
    border: 0;
    font-size: 32px;
    cursor: pointer;
  }

  .carousel {
    position: relative;
    width: 100%;

    * {
      box-sizing: border-box;
    }

    img {
      width: 100%;
      display: inline-block;
      pointer-events: none;
    }

    &.carousel-slider {
      position: relative;
      margin: 0;
      height: 100%;

      .control-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        font-size: 26px;
        bottom: 0;
        margin: 0 10px;
        padding: 5px;
        width: 36px;
        height: 36px;
        border-radius: 50%;

        &:hover {
          background: rgba(0, 0, 0, 0.5);
        }
      }

      .slider-wrapper {
        overflow: hidden;
        margin: auto auto 44px;
        width: 100%;
        transition: height 0.15s ease-in;

        .slider {
          margin: 0;
          padding: 0;
          position: relative;
          list-style: none;
          width: 100%;

          &.animated {
            transition: all 0.35s ease-in-out;
          }

          .slide {
            min-width: 100%;
            margin: 0;
            padding: 0 8px;
            position: relative;
            text-align: center;
            background: #fff;
            &:first-of-type {
              padding: 0 8px 0 0;
            }
            &:last-of-type {
              padding: 0 0 0 8px;
            }
            &:only-of-type {
              padding: 0;
            }

            img {
              width: 100%;
              height: 100%;
              vertical-align: top;
              border: 0;
              object-fit: cover;
            }

            iframe {
              display: inline-block;
              width: calc(100% - 80px);
              margin: 0 40px 40px;
              border: 0;
            }
          }

          .legend {
            transition: all 0.5s ease-in-out;
            position: absolute;
            bottom: 40px;
            left: 50%;
            margin-left: -45%;
            width: 90%;
            border-radius: 10px;
            background: #000;
            color: #fff;
            padding: 10px;
            font-size: 12px;
            text-align: center;
            opacity: 0.25;
          }
        }

        &.axis-horizontal .slider {
          display: flex;

          .slide {
            flex-direction: column;
            flex-flow: column;
          }
        }

        &.axis-vertical {
          display: flex;

          .slider {
            flex-direction: column;
          }
        }
      }
    }

    ul.control-dots {
      padding: 0;
    }

    .control-dots {
      position: absolute;
      bottom: 16px;
      margin: 0;
      text-align: center;
      width: 100%;
      z-index: 1;

      .dot {
        transition: opacity 0.25s ease-in;
        border: 1px solid ${props => props.theme.colors.harborGray};
        background: white;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        cursor: pointer;
        display: inline-block;
        margin: 0 2px;

        &.selected,
        &:hover {
          background: ${props => props.theme.colors.harborGray};
          border: 1px solid ${props => props.theme.colors.harborGray};
        }
      }
    }

    .carousel-status {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px;
      font-size: 10px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
      color: #fff;
    }

    &:hover .slide .legend {
      opacity: 1;
    }
  }
`;
