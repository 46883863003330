import React from 'react';

import styled from '@emotion/styled';
// import { getListingImageProps } from 'utils/listing';
// import { Image } from 'components';
// import listingNoPhoto from 'assets/images/listing_no_photo.svg';

interface ListingPhotoAndAddressProps {
  src?: string;
  address: string;
}

const ListingPhotoAndAddress: React.FC<ListingPhotoAndAddressProps> = ({ src, address }) => {
  // const imageProps = getListingImageProps(src);

  return (
    <StyledListingPhotoAndAddress>
      {/* <Image {...imageProps} error={listingNoPhoto} size="24x24" alt="mini-item" /> */}
      <span>{address}</span>
    </StyledListingPhotoAndAddress>
  );
};

export default ListingPhotoAndAddress;

const StyledListingPhotoAndAddress = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  img {
    margin: 0 12px 0 0;
    width: 24px;
    height: 24px;
    border-radius: ${props => props.theme.misc.borderRadius};
    vertical-align: middle;
  }
`;
